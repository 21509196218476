import styled from "styled-components";

export const CareerJobsCardContainer = styled.div`
  border: 1px solid rgba(13, 65, 121, 0.5);
  padding: 10px;
  background-color: transparent;
  margin-bottom: 10px;
`

export const CareerJobsCardWrapper = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  padding-left: 30px;
  padding-right: 30px;
  display: grid;
  align-items: center;
  justify-items: start;
  height: 80px;
  grid-template-columns: 1fr 1fr;
`

export const CareerAboutUsImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`

export const CareerAboutUsImage = styled.img`
  width: 100%;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`