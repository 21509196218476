import React from "react";
import {navigate} from "gatsby";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import Card from "../../cards/Card";

import {Body, Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {CareerJobsCardContainer, CareerJobsCardWrapper} from "../../styled/pages/about/career/CareerTableComponents";
import {Button} from "../../styled/button/ButtonComponents";

const CareerTable = ({jobs, whoiscards}) => {

    function navigateTo(props) {
        navigate(props)
    }

    return (
        <SectionLayoutGrid customBackground="middle" id={"Pozíciók"}>
            <Col>
                <Header2 color="white" textAlign="center" marginBottom="large">
                    Nyitott pozíciók
                </Header2>
                {jobs.map((item) =>
                    (
                        <CareerJobsCardContainer key={item.id}>
                            <CareerJobsCardWrapper>
                                <Body color="body-text">
                                    {item.title_white ? item.title_white + " " + item.title_color : item.title_color}
                                </Body>
                                <Button onClick={() => navigateTo("/hu/career/" + item.slug)} aria-hidden={true} justify="end">
                                    Részletek
                                </Button>
                            </CareerJobsCardWrapper>
                        </CareerJobsCardContainer>
                    )
                )}
                <Header2 color="white" marginTop="largest" marginBottom="small" textAlign="center">
                    {whoiscards.title}
                </Header2>
            </Col>
            {whoiscards.cards && whoiscards.cards.map((item) => (
                <Col span={4} key={item.id}>
                    <Card avatar={item.avatar.url} title={item.title}
                          description={item.description}/>
                </Col>))}
        </SectionLayoutGrid>
    )
}

export default CareerTable