import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import BenefitsCard from "../../cards/BenefitsCard";

import {LottieAnimation} from "../../styled/lotti/LottieAnimations";
import {Body, Title} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";
import {ImageLogo} from "../../styled/pages/products/header/HeaderComponents";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const CareerHeader = ({
                          title,
                          description,
                          cards,
                          src,
                      }) => {

    return (
        <>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid id={title} customBackground="middle">
                <Col span={6} center>
                    <Title color="white" marginBottom="normal">
                        {title}
                    </Title>
                    {src &&
                    <OnlyMobile>
                        <ImageLogo alt={title} src={src} className="image-for-modal"/>
                    </OnlyMobile>
                    }
                    {description &&
                    <Body color="body-text" marginBottom="small">
                        {description}
                    </Body>
                    }
                    {cards && cards.map((item) => (
                        <BenefitsCard title={item.title} avatar={item.avatar.url} isCircular smallerPadding/>
                    ))}
                </Col>
                {src &&
                <Col span={6} center>
                    <OnlyDesktop>
                        <ImageLogo alt={title} src={src} className="image-for-modal"/>
                    </OnlyDesktop>
                </Col>
                }
            </SectionLayoutGrid>
        </>
    )
}

export default CareerHeader