import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import Col from "../../styled/grid/Col";
import {CareerAboutUsImage, CareerAboutUsImageWrapper} from "../../styled/pages/about/career/CareerTableComponents";

const CareerAboutUs = ({colleague}) => {

    return (
        <SectionLayoutGrid customBackground="middle" id={colleague.title}>
            <Col>
                <CareerAboutUsImageWrapper>
                    {colleague.Pictures.map((item, index) => (
                        <Col span={4} key={index}>
                            <CareerAboutUsImage src={item.url} alt={item.caption} className="image-for-modal"/>
                        </Col>
                    ))}
                </CareerAboutUsImageWrapper>
            </Col>
        </SectionLayoutGrid>
    )
}

export default CareerAboutUs;

/*<H2 text={colleague.title}
                styleClass={`${global.fullWidth} ${global.textCenter} ${global.marginBottom40} ${global.marginTop40}`}/>
            {colleague.cards && colleague.cards.map((item) => (
                <div className={global.col4} key={item.id}>
                    <div className={`${global.card} ${styles.colleaguesCard} ${global.halfWidth}`}>
                        <img src={Avatar} alt={item.name}/>
                        <P text={item.said} styleClass={global.marginBottom40}/>
                    </div>
                    <div className={`${styles.colleaguesAvatarPlacing} ${global.fullWidth} ${global.gridDisplay}`}>
                        <div className={`${styles.colleaguesAvatar}`}
                             style={{backgroundImage: "url(" + item.avatar.url + ")"}}/>
                    </div>
                    <div className={global.fullWidth}>
                        <P text={item.name} styleClass={styles.colleaguesName}/>
                        <P text={item.job_title} styleClass={styles.colleaguesJob}/>
                    </div>
                </div>
            ))}*/