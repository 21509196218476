import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import CareerTable from "/src/components/pages/about/CareerTable";
import CareerHeader from "../../components/pages/about/CareerHeader";
import CareerAboutUs from "../../components/pages/about/CareerAboutUs";

const Career = ({data}) => {

    const {career} = data;
    const {nodes} = data.jobs;

    return (
        <Layout lang={career.lang} seo={career.SEO}>
            <CareerHeader
                title={career.product.title}
                description={career.product.description}
                src={career.product.image.url}
                cards={career.product.cards}
            />
            {nodes && nodes.length !== 0 &&
            <CareerTable
                jobs={nodes}
                whoiscards={career.Who_are_we}
            />}
            <CareerAboutUs
                colleague={career.Colleagues}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleCareer($locale: String) {
         career: strapiCareers(lang: { eq: $locale }) {
          lang
          product {
            title
            description
            cards {
              id
              title
              avatar {
                url
              }
            }
            image {
              url
            }
          }
          Who_are_we {
            title
            cards {
              id
              avatar {
                url
              }
              title
              description
            }
          }
          Colleagues {
            title
            cards {
              id
              said
              avatar {
                url
              }
              name
              job_title
            }
            Pictures {
              url
              caption
            }
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
      jobs: allStrapiJobs {
        nodes {
          title_color
          title_white
          slug
          id
        }
      }
   }
 `

export default Career